import type { RouteMiddleware } from '#app';
import type { RouteLocationNormalized } from '#vue-router';
import { registerVariantQueryKey } from '@/components/templates/RegisterUserTemplate/useRegisterValiant';

type Predicate = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) => boolean;
type Provider = RouteMiddleware;

const REDIRECT_DESTINATION_PREDICATES: readonly (readonly [
  Predicate,
  Provider,
])[] = [
  // 遷移後のページがregisterVariantを持っている場合は、遷移後のregisterVariantを採用する
  [
    (to, _) => registerVariantQueryKey in to.query,
    (to, _) => ({
      path: '/register',
      query: {
        redirect: to.fullPath,
        [registerVariantQueryKey]: to.query[registerVariantQueryKey],
      },
    }),
  ],
  // 遷移前のページがregisterVariantを持っている場合は、遷移前のregisterVariantを引き継ぐ
  [
    (_, from) => registerVariantQueryKey in from.query,
    (to, from) => ({
      path: '/register',
      query: {
        redirect: to.fullPath,
        [registerVariantQueryKey]: from.query[registerVariantQueryKey],
      },
    }),
  ],
  [
    (to, _) => to.fullPath.match(/^\/chat\/diagnosis/) != null,
    (to, _) => ({
      path: '/register',
      query: { redirect: to.fullPath },
    }),
  ],
  [
    (_, from) => from.fullPath.match(/^\/partner\//) != null,
    (to, _) => ({
      path: '/register',
      query: { redirect: to.fullPath },
    }),
  ],
  [
    (_, from) => from.fullPath.match(/^\/planner\//) != null,
    (to, _) => ({
      path: '/register',
      query: { redirect: to.fullPath },
    }),
  ],
  [
    (_, from) => from.fullPath.match(/^\/booking_request\//) != null,
    () =>
      createError({
        statusCode: 401,
        statusMessage: '認証情報がありません',
      }),
  ],
] as const;

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (import.meta.server) return;
  const { isAuthenticated } = await useAuthenticationState();
  if (isAuthenticated.value) return;
  return (
    REDIRECT_DESTINATION_PREDICATES.find(([pred, _]) => pred(to, from))?.[1](
      to,
      from
    ) ?? {
      path: '/login',
      query: { redirect: to.fullPath },
    }
  );
});
